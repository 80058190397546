import React from 'react'

import _get from 'lodash/get'
import { Link, graphql } from 'gatsby'
import { DiscussionEmbed } from 'disqus-react'

import Layout from '../components/Layout'
import Nav from '../components/Nav'

import {ArrowRight, ArrowLeft } from 'react-feather'

export const byCategory = (posts, title, contentType) => {
  const isCategory = contentType === 'blog/category'
  const byCategory = (post) =>
    post.categories &&
    post.categories.filter((cat) => cat.category === title).length
  return isCategory ? posts.filter(byCategory) : posts
}

export const SinglePostTemplate = ({
  id,
  title,
  date,
  body,
  timeToRead,
  nextPostURL,
  prevPostURL,
  commentConfig,
  categories = [],
  postCategories = []
}) => {
  // TODO: WORKAROUND IF BLOG HAS MULTIPLE CATEGORIES
  const linkCategory = postCategories.filter(
    (cat) => cat.title === categories[0].category
  )
  const disqusConfig = {
    shortname: process.env.GATSBY_DISQUS_NAME,
    config: { identifier: id, title }
  }

  return (
    <div class="container w-full md:max-w-4xl mx-auto py-16">
      <div class="w-full px-4 md:px-6 text-xl text-grey-darkest leading-normal">
        <hr class="border-b-2 border-grey-light mb-8 mx-4" />
        <div className="pageHeader">
          <div className="text-center">
            <h1 className="font-bold break-normal text-3xl md:text-5xl">
              {title}
            </h1>
          </div>
          <div className="flex flex-wrap justify-center text-center pt-4 pb-16">
            <p className="pr-4">{date}</p>
            <Link className="px-4" to={linkCategory[0]?.slug}>
              {linkCategory[0]?.title}
            </Link>
            <p>{timeToRead} min read</p>
          </div>
        </div>

        <hr class="border-b-2 border-grey-light mb-8 mx-4" />

        <div className="markdown" dangerouslySetInnerHTML={{ __html: body }} />
        {/* <div class="text-base md:text-sm text-grey px-4 py-6">
          Tags:{' '}
          <a
            href="#"
            class="text-base md:text-sm text-teal no-underline hover:underline"
          >
            Link
          </a>{' '}
          .{' '}
          <a
            href="#"
            class="text-base md:text-sm text-teal no-underline hover:underline"
          >
            Link
          </a>
        </div> */}
      </div>

      <div class="font-sans flex justify-between content-center px-4 pb-12">
        <div class="text-left">
          <span class="text-xs md:text-sm font-normal text-grey-dark">
            {prevPostURL && (
              <Link
                className="SinglePost--Pagination--Link prev"
                to={prevPostURL.fields.slug}
              >
                <ArrowLeft size="16" className="inline-block "/> Previous Post
              </Link>
            )}
          </span>
          <br />
          <p>
            {prevPostURL && (
              <Link
                className="break-normal text-base md:text-sm text-teal font-bold no-underline hover:underline"
                to={prevPostURL.fields.slug}
              >
                {prevPostURL.frontmatter.title}
              </Link>
            )}
          </p>
        </div>
        <div class="text-right">
          <span class="text-xs md:text-sm font-normal text-grey-dark">
            {nextPostURL && (
              <Link
                className="SinglePost--Pagination--Link prev"
                to={nextPostURL.fields.slug}
              >
                Next Post <ArrowRight size="16" className="inline-block "/>
              </Link>
            )}
          </span>
          <br />
          <p>
            {nextPostURL && (
              <Link
                className="break-normal text-base md:text-sm text-teal font-bold no-underline hover:underline"
                to={nextPostURL.fields.slug}
              >
                {nextPostURL.frontmatter.title}
              </Link>
            )}
          </p>
        </div>
      </div>
      <DiscussionEmbed {...disqusConfig} />
    </div>
  )
}

// Export Default SinglePost for front-end
const SinglePost = ({ data: { post, allPosts, postCategories } }) => {
  const thisEdge = allPosts.edges.find((edge) => edge.node.id === post.id)

  return (
    <Layout
      meta={post.frontmatter.meta || false}
      title={post.frontmatter.title || false}
    >
      <Nav />
      <SinglePostTemplate
        {...post}
        {...post.frontmatter}
        body={post.html}
        postCategories={postCategories.edges.map((post) => ({
          ...post.node,
          ...post.node.frontmatter,
          ...post.node.fields
        }))}
        nextPostURL={_get(thisEdge, 'next')}
        prevPostURL={_get(thisEdge, 'previous')}
      />
    </Layout>
  )
}

export default SinglePost

export const pageQuery = graphql`
  ## Query for SinglePost data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## $id is processed via gatsby-node.js
  ## query name must be unique to this file
  query SinglePost($id: String!) {
    post: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      id
      frontmatter {
        title
        template
        subtitle
        date(formatString: "MMMM Do, YYYY")
        categories {
          category
        }
      }
      timeToRead
    }

    allPosts: allMarkdownRemark(
      filter: { fields: { contentType: { eq: "blog" } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          id
        }
        next {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
        previous {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }

    postCategories: allMarkdownRemark(
      filter: { fields: { contentType: { eq: "blog/category" } } }
      sort: { order: ASC, fields: [frontmatter___title] }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`
